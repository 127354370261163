import React from 'react';
import PropTypes from 'prop-types';

const PreviousYears = ({ currentYear }) => {
  const years = {
    start: 2012, // Earliest year
    end: currentYear - 1, // Most recent previous year
    skip: [], // Array of years to skip, if needed
    list: [] // This is populated below
  };

  // Loop through start and end years in REVERSE ORDER
  for (let i = years.end; i >= years.start; i -= 1) {
    if (!years.skip.includes(i)) {
      // Only continue if year is NOT in skip array
      years.list.push(
        <option value={i} key={i}>
          {i}
        </option>
      ); // Push option tag to array
    }
  }

  const handleNewYear = year => {
    if (year !== 'default') {
      window.open(
        `https://www.fastcompany.com/innovation-by-design/${year}`,
        '_blank'
      );
    }
  };

  return (
    <div className="select-wrapper">
      <select onChange={e => handleNewYear(e.currentTarget.value)}>
        <option value="default">Choose Year</option> {/* Default option */}
        {years.list} {/* Previously assembled array of option tags */}
      </select>
    </div>
  );
};

PreviousYears.propTypes = {
  currentYear: PropTypes.number.isRequired
};

export default PreviousYears;
