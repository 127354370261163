import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const BADGE_TEXT = 'Design of the Year';

const WinnersListItem = ({
  company,
  category,
  title,
  url,
  'Design of the Year': badge,
  description = 'Ad consectetur commodo amet excepteur magna veniam ullamco est duis consequat laborum aute minim.',
  icon = 'https://placehold.jp/3d4070/ffffff/100x100.png?text=ICON',
  image = 'https://placehold.jp/3d4070/ffffff/700x394.png?text=IBD%20IMAGE'
}) => (
  <div
    className={clsx(['list-item', 'winner'])}
    onClick={() => window.open(url, '_blank')}
  >
    <div className={clsx(['list-item-content'])}>
      <div className={clsx(['list-item-content__icon'])}>
        <img src={icon} alt="icon" />
      </div>

      <div className={clsx(['list-item-content__company-info'])}>
        <div className={clsx(['list-item-content__company-info--category'])}>
          {category}
        </div>
        <h2 className={clsx(['list-item-content__company-info--project'])}>
          {title}
        </h2>
        <div className={clsx(['list-item-content__company-info--company'])}>
          {company}
        </div>
        {badge && (
          <div className={clsx(['list-item-content__company-info--badge'])}>
            {BADGE_TEXT}
          </div>
        )}
      </div>

      <div className={clsx(['list-item-content__company-description'])}>
        {description}
      </div>

      <div className={clsx(['list-item-content__company-image'])}>
        {image && <img src={image} alt={title} />}
      </div>

      <div className={clsx(['list-item-content__arrow'])}>→</div>
    </div>
  </div>
);

WinnersListItem.propTypes = {
  company: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  'Design of the Year': PropTypes.bool,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
};

WinnersListItem.defaultProps = {
  'Design of the Year': false
};

export default WinnersListItem;
