import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const HonoreesListItem = ({
  title,
  url,
  description = 'Ad consectetur commodo amet excepteur magna veniam ullamco est duis consequat laborum aute minim.',
  icon = 'https://placehold.jp/3d4070/ffffff/100x100.png?text=ICON'
}) => (
  <div
    className={clsx(['list-item', 'honoree'])}
    onClick={() => window.open(url, '_blank')}
  >
    <div className={clsx(['list-item-content'])}>
      <div className={clsx(['list-item-content__icon'])}>
        <img src={icon} alt="icon" />
      </div>

      <div className={clsx(['list-item-content__honoree-info'])}>
        <h2 className={clsx(['list-item-content__honoree-info--title'])}>
          {title}
        </h2>
        <div className={clsx(['list-item-content__honoree-info--description'])}>
          {description}
        </div>
      </div>

      <div className={clsx(['list-item-content__arrow'])}>→</div>
    </div>
  </div>
);

HonoreesListItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default HonoreesListItem;
